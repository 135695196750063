import React from "react"
import { useMediaQuery } from 'react-responsive'

const NotFoundPage = () => {
        return <div className="mosaic-blueprint-theme mosaic mosaic-drop-target">
            <div className="mosaic-root">
                <div className="mosaic-tile" style={{top: "0%", right: "0%", left: "0%" }}>
                    <div className="mosaic-window">
                        <div className="mosaic-window-toolbar">
                            <div className="mosaic-window-title">
                                FEHLER
                            </div>
                        </div>
                        <div className="mosaic-window-body">
                            <div className="description">
                                <p>
                                    404. Diese Seite existiert nicht.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

}

export default NotFoundPage
